













































































































































































































































































import {
  SfHeading, SfInput, SfButton, SfSelect,
} from '@storefront-ui/vue';
import {
  ref,
  computed,
  onMounted,
  defineComponent,
  useRouter,
  useContext,
} from '@nuxtjs/composition-api';
import { required, min, digits } from 'vee-validate/dist/rules';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import userShippingGetters from '~/modules/customer/getters/userShippingGetters';
import addressGetter from '~/modules/customer/getters/addressGetter';
import { useCountrySearch } from '~/composables';
import type {
  Country, AvailableShippingMethod, CustomerAddress, Customer,
} from '~/modules/GraphQL/types';
import useShipping from '~/modules/checkout/composables/useShipping';
import useUser from '~/modules/customer/composables/useUser';
import useUserAddress from '~/modules/customer/composables/useUserAddress';
import {
  addressFromApiToForm, CheckoutAddressForm, findUserAddressIdenticalToSavedCartAddress, getInitialCheckoutAddressForm,
} from '~/helpers/checkout/address';
import { mergeItem } from '~/helpers/asyncLocalStorage';
import { isPreviousStepValid } from '~/helpers/checkout/steps';

extend('required', {
  ...required,
  message: 'This field is required',
});
extend('min', {
  ...min,
  message: 'The field should have at least {length} characters',
});
extend('digits', {
  ...digits,
  message: 'Please provide a valid phone number',
});

export default defineComponent({
  name: 'ShippingStep',
  components: {
    SfHeading,
    SfInput,
    SfButton,
    SfSelect,
    ValidationProvider,
    ValidationObserver,
    UserShippingAddresses: () => import('~/modules/checkout/components/UserShippingAddresses.vue'),
    VsfShippingProvider: () => import('~/modules/checkout/components/VsfShippingProvider.vue'),
  },
  setup() {
    const router = useRouter();
    const { app } = useContext();
    const userShipping = ref<Customer | null | any>(null);
    const {
      load: loadShipping,
      save: saveShipping,
      loading: isShippingLoading,
    } = useShipping();
    const {
      load: loadUserShipping,
      setDefaultAddress,
    } = useUserAddress();

    const {
      load: loadCountries,
      search: searchCountry,
    } = useCountrySearch();
    const countries = ref<Country[]>([]);
    const country = ref<Country | null>(null);
    const { isAuthenticated } = useUser();
    const shippingDetails = ref<CheckoutAddressForm>(getInitialCheckoutAddressForm());
    const shippingMethods = ref<AvailableShippingMethod[]>([]);
    const currentAddressId = ref<number | null>(null);

    const isSetAsDefaultRequested = ref(false);
    const isFormSubmitted = ref(false);
    const isAddNewAddressFormVisible = ref(true);

    const isShippingDetailsStepCompleted = ref(false);
    const addresses = computed(() => userShippingGetters.getAddresses(userShipping.value));

    const canMoveForward = computed(() => !isShippingLoading.value && shippingDetails.value && Object.keys(
      shippingDetails.value,
    ).length > 0);

    const hasSavedShippingAddress = computed(() => {
      if (!isAuthenticated.value || !userShipping.value) {
        return false;
      }
      return addresses.value.length > 0;
    });

    const countriesList = computed(() => addressGetter.countriesList(countries.value));

    const regionInformation = computed(() => addressGetter.regionList(country.value));

    const handleAddressSubmit = (reset: () => void) => async () => {
      const addressId = currentAddressId.value;
      const shippingDetailsData = {
        ...shippingDetails.value,
        customerAddressId: addressId,
        save_in_address_book: false,
      };
      await mergeItem('checkout', { shipping: shippingDetailsData });

      const shippingInfo = await saveShipping({ shippingDetails: shippingDetailsData });
      shippingMethods.value = shippingInfo?.available_shipping_methods ?? [];

      if (addressId !== null && isSetAsDefaultRequested.value) {
        const [chosenAddress] = userShippingGetters.getAddresses(
          userShipping.value,
          { id: addressId },
        );
        chosenAddress.default_shipping = isSetAsDefaultRequested.value;
        if (chosenAddress) {
          await setDefaultAddress({ address: chosenAddress });
          userShipping.value = await loadUserShipping(true);
        }
      }
      reset();
      isShippingDetailsStepCompleted.value = true;
    };

    const handleAddNewAddressBtnClick = () => {
      currentAddressId.value = null;
      shippingDetails.value = getInitialCheckoutAddressForm();
      isAddNewAddressFormVisible.value = true;
      isShippingDetailsStepCompleted.value = false;
    };

    const handleSetCurrentAddress = async (customerAddress: CustomerAddress) => {
      const id = customerAddress?.id;
      currentAddressId.value = id;
      if (id) {
        isAddNewAddressFormVisible.value = false;
      }
      shippingDetails.value = addressFromApiToForm(customerAddress);
      country.value = customerAddress.country_code ? await searchCountry({ id: customerAddress.country_code }) : null;
      isShippingDetailsStepCompleted.value = false;
    };

    const changeShippingDetails = (field: keyof CheckoutAddressForm, value: string) => {
      shippingDetails.value[field] = value;
      isShippingDetailsStepCompleted.value = false;
      currentAddressId.value = null;
    };

    const changeCountry = async (id: string) => {
      changeShippingDetails('country_code', id);
      const newCountry = await searchCountry({ id });
      shippingDetails.value.region = '';
      country.value = newCountry;
    };

    onMounted(async () => {
      const validStep = await isPreviousStepValid('user-account');
      if (!validStep) {
        await router.push(app.localeRoute({ name: 'user-account' }));
      }
      const [loadedShippingInfoBoundToCart, loadedUserShipping, loadedCountries] = await Promise.all([
        loadShipping(),
        loadUserShipping(),
        loadCountries(),
      ]);
      const [defaultAddress = null] = userShippingGetters.getAddresses(loadedUserShipping, { default_shipping: true });
      const wasShippingAddressAlreadySetOnCart = Boolean(loadedShippingInfoBoundToCart);

      if (wasShippingAddressAlreadySetOnCart) {
        const userAddressIdenticalToSavedCartAddress = findUserAddressIdenticalToSavedCartAddress(
          loadedUserShipping?.addresses,
          loadedShippingInfoBoundToCart,
        );
        handleSetCurrentAddress({ ...loadedShippingInfoBoundToCart, id: userAddressIdenticalToSavedCartAddress?.id });
      } else if (defaultAddress) {
        handleSetCurrentAddress(defaultAddress);
      }
      if (shippingDetails.value?.country_code) {
        country.value = await searchCountry({ id: shippingDetails.value.country_code });
      }
      userShipping.value = loadedUserShipping;
      countries.value = loadedCountries;
    });

    return {
      isAddNewAddressFormVisible,
      canMoveForward,
      changeCountry,
      changeShippingDetails,
      countries,
      countriesList,
      country,
      currentAddressId,
      handleAddNewAddressBtnClick,
      handleAddressSubmit,
      handleSetCurrentAddress,
      hasSavedShippingAddress,
      isAuthenticated,
      isFormSubmitted,
      isShippingDetailsStepCompleted,
      isShippingLoading,
      regionInformation,
      searchCountry,
      isSetAsDefaultRequested,
      shippingDetails,
      shippingMethods,
      addresses,
    };
  },
});
